// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_mMqLX{color:var(--color-icon-normal);font-size:20px}.zp_mMqLX.zp_S3c_z{color:var(--color-icon-light)}.zp_mMqLX.zp_TpVaT{font-size:10px}.zp_mMqLX.zp_glZ0B{font-size:12px;font-weight:400;line-height:16px}.zp_mMqLX.zp_kBZv0{color:#fff}.zp_mMqLX.zp__yeN8{color:var(--color-negative-40)}.zp_mMqLX.zp_kiQGD{font-size:14px;font-weight:400;line-height:20px}.zp_mMqLX.zp_Xc57O{font-size:18px}.zp_mMqLX.zp_HYlfF{font-size:30px}.zp_mMqLX.zp_CmZN2{font-size:100px}.zp_mMqLX.zp_pWop8{color:inherit}.zp_mMqLX.zp_CUAal{font-size:16px;font-weight:400;line-height:24px;color:#fff;margin-right:5px}.zp_mMqLX.zp_nyj7_{margin-right:10px}.zp_mMqLX.zp_YUj4p{margin-left:10px}.zp_mMqLX.zp_GQ7JH{display:flex;align-items:center;justify-content:center}.zp_mMqLX.zp_c4RoR{color:var(--color-primary-50)}.zp_mMqLX.zp_RAh4r{line-height:1}.zp_YicAV{cursor:pointer}.zp_EASSb:hover{color:var(--color-primary-50)}.zp_EASSb:active{color:var(--color-text-links)}.zp_N8MRa:hover use{fill:var(--color-primary-50)}.zp_LDMU7:hover{color:var(--color-negative-40)}.zp_LDMU7:active{color:var(--color-negative-50)}.zp_XOeJR{color:var(--color-text-caution)}.zp_S5lqS{color:var(--color-positive-50)}.zp_FiN2F{color:var(--color-secondary-60)}.zp_Z2ViK{color:var(--color-negative-40)}.zp_XAeBG{color:var(--color-positive-60)}.zp_Wv4yt{color:var(--color-negative-40)}.zp_XxRWV{color:var(--color-primary-50)}.zp_jepLh{color:var(--color-neutral-40)}.zp_Ly3Y1{color:var(--color-caution-50)}.zp_ry9AG{color:var(--color-text-caution)}.zp_Fy5Gu{color:var(--color-neutral-30)}.zp_jA_Ft{color:var(--color-secondary-50)}@keyframes zp_LFAdY{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.zp_LFAdY,.zp_Irwp0::before{animation-name:zp_LFAdY;animation-duration:1.5s;animation-duration:var(--icon-animation-duration, 1.5s);animation-iteration-count:infinite;animation-timing-function:linear}.zp_Irwp0::before{animation-direction:reverse}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "zp_mMqLX",
	"light": "zp_S3c_z",
	"tiny": "zp_TpVaT",
	"small": "zp_glZ0B",
	"white": "zp_kBZv0",
	"danger": "zp__yeN8",
	"small-medium": "zp_kiQGD",
	"medium": "zp_Xc57O",
	"medium-large": "zp_HYlfF",
	"large": "zp_CmZN2",
	"no-color": "zp_pWop8",
	"for-badge": "zp_CUAal",
	"right-padded": "zp_nyj7_",
	"leftPadded": "zp_YUj4p",
	"custom-image": "zp_GQ7JH",
	"active": "zp_c4RoR",
	"adjustLineHeight": "zp_RAh4r",
	"clickable": "zp_YicAV",
	"clickable-hover": "zp_EASSb",
	"custom-svg": "zp_N8MRa",
	"color-style-action-danger": "zp_LDMU7",
	"color-style-canvas-dark": "zp_XOeJR",
	"color-style-toast-success": "zp_S5lqS",
	"color-style-toast-warning": "zp_FiN2F",
	"color-style-toast-error": "zp_Z2ViK",
	"color-style-status-success": "zp_XAeBG",
	"color-style-status-error": "zp_Wv4yt",
	"color-style-blue": "zp_XxRWV",
	"color-style-light": "zp_jepLh",
	"color-style-orange": "zp_Ly3Y1",
	"color-style-orange2": "zp_ry9AG",
	"color-style-gray4": "zp_Fy5Gu",
	"color-style-yellow": "zp_jA_Ft",
	"rotating": "zp_LFAdY",
	"rotatingClockwise": "zp_Irwp0"
};
export default ___CSS_LOADER_EXPORT___;
