// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_smTb5{display:flex;flex-direction:column;gap:8px;padding:12px 24px 8px}.zp_smTb5.zp_O9EXL{padding-bottom:0}.zp_zEuMY{display:flex;flex-direction:column;gap:2px}.zp__Tjw6{display:flex;align-items:center;gap:8px;gap:var(--spacing-8, 8px);justify-content:space-between}.zp_r9xLB{font-size:14px;font-weight:400;line-height:20px;color:var(--color-text-body);margin:0}.zp_YqbVJ{display:flex;align-items:center;gap:8px;overflow:hidden}.zp_YqbVJ h1{font-size:16px;font-weight:400;line-height:24px;font-weight:600;overflow-x:auto;white-space:nowrap;flex:1}.zp_YqbVJ .zp_YDVlT{font-size:20px}.zp_sgiYl{margin-top:-8px;border-bottom:1px solid var(--color-neutral-20)}.zp_nQ45Z>i{color:var(--color-base-neutral-60)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "zp_smTb5",
	"withTabs": "zp_O9EXL",
	"mainHeader": "zp_zEuMY",
	"headerTitle": "zp__Tjw6",
	"subtext": "zp_r9xLB",
	"headerContent": "zp_YqbVJ",
	"headerIcon": "zp_YDVlT",
	"headerBorder": "zp_sgiYl",
	"closeButton": "zp_nQ45Z"
};
export default ___CSS_LOADER_EXPORT___;
