/* eslint-disable camelcase */
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

import classNames from 'classnames';

import Tippy, { type TippyProps } from '@tippyjs/react';

// Styles
import styles from './Tooltip.module.scss';

// Constants
import { PLACEMENT_MAP } from './constants';
import arrowSVG from './TooltipArrow';

type TooltipProps = {
  /**
   * The child elements that the tooltip wraps around.
   *
   * Make sure that the component is forwarding ref. */
  children?: TippyProps['children'];

  /** The content of the tooltip */
  content?: TippyProps['content'];

  /** Control what actions makes the tooltip appear */
  trigger?: TippyProps['trigger'];

  /**
   * Controls whether the tooltip is visible or not
   * @default false
   */
  visible?: TippyProps['visible'];

  /**
   * Defines the placement of the tooltip relative to the reference element
   * @default top
   */
  placement?:
    | 'top'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'auto'
    | 'auto-left'
    | 'auto-right';

  /**
   * The duration of the tooltip's transition
   * @default 200
   */
  duration?: TippyProps['duration'];

  /**
   * Delay in milliseconds for showing and hiding the tooltip
   * @default 120
   */
  delay?: TippyProps['delay'];

  /**
   * Whether the tooltip will be interactive (e.g., allow hovering over it)
   * @default false
   */
  interactive?: TippyProps['interactive'];

  /**
   * Indicates if the tooltip arrow should be shown
   * @default true
   */
  arrow?: boolean;

  /**
   * Determines the alignment of the content.
   * @default false
   */
  multiline?: boolean;

  /**
   * Invoked once the tippy has been created.
   */
  onCreate?: TippyProps['onCreate'];

  /**
   * Invoked once the tippy has been triggered by a DOM event (e.g. mouseenter).
   */
  onTrigger?: TippyProps['onTrigger'];

  /**
   * Invoked once the tippy has been untriggered by a DOM event (e.g. mouseleave).
   */
  onUntrigger?: TippyProps['onUntrigger'];

  /**
   * When interactive is true, the tooltip will be appended to the targets parent element for accessibility reasons by default.
   * If you are experiencing issues with positioning, add appendTo: document.body to your props.
   */
  appendTo?: TippyProps['appendTo'];

  /** className for the tooltip. Usage of this prop is restricted. */
  UNSAFE_className?: TippyProps['className'];

  /**
   * Whether to wrap the tooltip content inside a `<span>`, only utilize this if ref of the component is not usable. Usage of this prop is restricted.
   */
  UNSAFE_wrap?: boolean;
};

/**
 * The Tooltip component is a user interface element that provides additional information about an element when hovered over or focused.
 * It acts as a brief explanation or an informational hint that enhances user understanding without overwhelming the UI.
 *
 * Tooltip component is a wrapper on [Tippy](https://www.npmjs.com/package/@tippyjs/react) library. It provides a simple and flexible API to create tooltips.
 *
 * ### External references
 * - [tippyjs documentation](https://atomiks.github.io/tippyjs/v6/all-props/)
 * - [@tippyjs/react documentation](https://github.com/atomiks/tippyjs-react)
 *
 */
export function Tooltip(props: TooltipProps) {
  // eslint-disable-next-line camelcase
  const {
    content,
    children,
    placement = 'top',
    interactive,
    visible,
    duration = 120,
    delay = 200,
    arrow = true,
    multiline = false,
    onCreate,
    trigger,
    onTrigger,
    onUntrigger,
    appendTo,
    UNSAFE_className,
    UNSAFE_wrap,
  } = props;

  if (!hasContent(content)) {
    return <>{props.children}</>;
  }

  const _children = UNSAFE_wrap ? <span>{children}</span> : children;

  return (
    <Tippy
      theme="tooltip-2.0"
      className={classNames(
        'apolloio-css-vars-reset',
        styles.tooltip,
        multiline && styles.multiline,
        UNSAFE_className,
      )}
      interactive={interactive}
      content={content}
      duration={duration}
      delay={delay}
      arrow={arrow ? arrowSVG : false}
      // @ts-expect-error `undefined` should be fine as an index type
      placement={PLACEMENT_MAP[placement] || placement}
      visible={visible}
      onCreate={(instance) => {
        // eslint-disable-next-line no-param-reassign
        instance.popper.dataset.theme = 'reversed'; // [data-theme='reversed']
        if (typeof onCreate === 'function') {
          onCreate(instance);
        }
      }}
      appendTo={appendTo ?? getClosestBody}
      trigger={trigger}
      onTrigger={onTrigger}
      onUntrigger={onUntrigger}
    >
      {_children}
    </Tippy>
  );
}

function getClosestBody(element: Element) {
  // "as Element" type-cast is required because Tippy doesn't allow HTMLBodyElement -_-
  return (
    element.closest('[role="dialog"]') ??
    element.closest('[data-ds2-root]') ??
    (element.closest('body') as Element)
  );
}

function hasContent(content: TooltipProps['content']) {
  // Return false if content is falsy, except for 0
  if (!content && content !== 0) {
    return false;
  }

  return true;
}
