import React from 'react';

import styles from './Spinner.module.scss';
import classNames from 'classnames';

type SpinnerProps = {
  scale?: number;
  color?: string;
  className?: string;
};

// Copied from https://github.com/tienpham94/react-awesome-spinners/blob/master/src/Spinner/index.js

export default function Spinner({ color, scale, className }: SpinnerProps) {
  return (
    <div
      className={classNames(styles.rippleSpinner, className)}
      style={{ color, '--scale': scale }}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

Spinner.defaultProps = {
  scale: 0.75,
  color: '#00bfff',
  className: '',
};
