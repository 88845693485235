import $ from 'jquery';
import { cloneDeep, isEmpty } from 'lodash-es';
import { getCurrentSite, getCurrentUrl } from 'chrome-extension/inject/core/lib/locationUtils';
import { logExtensionErrorToSentry } from 'chrome-extension/inject/core/lib/sentryUtils';
import { encodeClassName } from 'chrome-extension/inject/core/lib/utils';
import type { Team } from '$types/entities/team';
import moment from 'moment';
import { isTeamPaying } from 'app/utils/TeamUtils';

export const getLinkedinPageModality = () => (isProfile() ? 'contact' : 'account');

/**
 * Current use case: make all LI overlays' z-index less than our Modal
 *  whenever a Modal is mounted and remove the overrides on Modal unmount.
 */

export const overrideLIOverlaysZIndex = ({
  zIndex = '19999',
  maybeRemoveOverride = false,
} = {}) => {
  const apolloOverlay = document.querySelector(encodeClassName('apollo-overlay-container'));
  const lushaOverlay = document.querySelector('#LU__extension_wrapper');

  if (maybeRemoveOverride) {
    if (apolloOverlay instanceof HTMLElement) {
      apolloOverlay.style.zIndex = '';
    }
    if (lushaOverlay instanceof HTMLElement) {
      lushaOverlay.style.zIndex = '';
    }
  } else {
    if (apolloOverlay instanceof HTMLElement) {
      apolloOverlay.style.zIndex = zIndex;
    }
    if (lushaOverlay instanceof HTMLElement) {
      lushaOverlay.style.zIndex = zIndex;
    }
  }
};

/**
 * This is used for old code which filters "LinkedIn Member" rows.
 *  Moving forward, we should find unique properties in the DOM
 *    and check those instead of looking at the text.
 */
const isLinkedinMember = {
  'LinkedIn Member': true,
  'عضو LinkedIn': true,
  'Člen LinkedIn': true,
  'LinkedIn-medlem': true,
  'LinkedIn Mitglied': true,
  'Miembro de LinkedIn': true,
  'Membre de LinkedIn': true,
  'Anggota LinkedIn': true,
  'Utente LinkedIn': true,
  LinkedInメンバー: true,
  'LinkedIn 회원': true,
  'Ahli LinkedIn': true,
  'LinkedIn-lid': true,
  'Użytkownik LinkedIn': true,
  'Usuário do LinkedIn': true,
  'Membru LinkedIn': true,
  'Участник LinkedIn': true,
  'Medlem på LinkedIn': true,
  'สมาชิก LinkedIn': true,
  'Miyembro ng LinkedIn': true,
  'LinkedIn Üyesi': true,
  领英会员: true,
  'LinkedIn 會員': true,
};

export function isKeyofIsLinkedinMember(
  name: string | undefined | null,
): name is keyof typeof isLinkedinMember {
  return !!name && name in isLinkedinMember;
}

export const NEW_SEARCH_PERSON_ROW_SELECTOR = '.reusable-search__result-container';

export function cleanHTML(strCode: string) {
  const $html = $(strCode.bold());
  $html.find('script').remove();
  $html.find('meta').remove();
  $html.find('style').remove();
  $html.find('link').remove();
  //The code section on linkedin is deceptive. It doesn't always match with what the users see.
  //DO NOT use this section for parsing!!
  $html.find('code').remove();
  $html.find('g').remove();
  return $html.html();
}

//sales navigaotor results
export function salesforceNavigatorSearchLoading() {
  if ($('.results-loader-wrapper').length > 0 && !$('.results-loader-wrapper').hasClass('hidden')) {
    //sales navigaotor results
    return true;
  }

  // new sales navigator results page (08.28.2018)
  const resultsEl = document.getElementById('results');
  if (resultsEl?.classList.contains('is-fetching')) {
    return true;
  }
  return false;
}

export function newSearchLoading() {
  // 20180222JP: LI has at least two search locations with different markup.
  // The "index search" at https://www.linkedin.com/search/results/index
  // The "people search" at https://www.linkedin.com/search/results/people
  const hasNewPeopleSearchResults = $(NEW_SEARCH_PERSON_ROW_SELECTOR).length > 0;
  if (isNewSearch() && !hasNewPeopleSearchResults) {
    return true;
  }

  return false;
}

export function customProfileLoading() {
  return profileDLoading() || profileFLoading() || profileHLoading() || profileILoading();
}

export function profileDLoading() {
  if (isProfileD() && $('.experience-section .pv-position-entity').length === 0) {
    return true;
  }
  return false;
}

export function profileFLoading() {
  if (isProfileF() && $('.background-details').length === 0) {
    return true;
  }
  return false;
}

export function profileHLoading() {
  /** This profile type has multiple versions of their Experience aka Companies container */
  if (
    isProfileH() &&
    $('#oc-background-section .pv-profile-section-pager').length === 0 &&
    $('section[id*="-EXPERIENCE-"]:not([id*="-VOLUNTEERING-EXPERIENCE-"])').length === 0 &&
    $('div#experience').length === 0 &&
    $('main section.pvs-loader__profile-card').length > 0
  ) {
    return true;
  }
  return false;
}

export function profileILoading() {
  /** This profile type has multiple versions of their Experience aka Companies container */
  if (
    isProfileI() &&
    $('#oc-background-section .pv-profile-section-pager').length === 0 &&
    $('section[id*="-EXPERIENCE-"]:not([id*="-VOLUNTEERING-EXPERIENCE-"])').length === 0 &&
    $('div#experience').length === 0 &&
    $('main section.pvs-loader__profile-card').length > 0
  ) {
    return true;
  }
  return false;
}

// 20180531JP: LI throws up a ton of spinners, including ones that are not visible.
//  This approach checks to see if the main info has loaded, i.e. the top card and
//  positions. However, further sections below like recommendations may not be
//  loaded until the user scrolls them into view.
//
//  Remember that this needs to work for both direct and indirect views.
export function profileSalesNavigatorCLoading() {
  if (!isSalesNavigatorProfile()) {
    return false;
  }

  const loaderSelector = 'li-icon[type=loader]';
  return $(`.profile-topcard ${loaderSelector}, #profile-positions ${loaderSelector}`).length > 0;
}

// For tracking
/**
 *
 *
 */
export function getLinkedinVersion(
  baseUrl?: string,
): 'Sales Navigator' | 'Recruiter' | 'Regular' | 'None' {
  const url = baseUrl ?? getCurrentUrl().toLowerCase();
  const site = getCurrentSite(url);
  if (url.includes('linkedin.com/sales')) {
    return 'Sales Navigator';
  } else if (url.includes('linkedin.com/talent')) {
    return 'Recruiter';
  } else {
    if (site === 'linkedin') {
      return 'Regular';
    }
    return 'None';
  }
}

// For tracking
export function getLinkedinPage() {
  if (isFeed()) {
    return 'Feed Page';
  } else if (isSearch()) {
    return 'Search Results Page';
  } else if (isProfile()) {
    return 'Profile Page';
  } else if (isCompany()) {
    return 'Company Page';
  } else {
    return 'Other';
  }
}

/** For sentry logging */
export function getLinkedinSearchPage() {
  if (isNewSearch()) {
    return 'Regular LinkedIn Search Page';
  } else if (isCompanyPeople()) {
    return 'Regular LinkedIn Company People Page';
  } else if (isSalesNavigatorSearch()) {
    return 'Sales Navigator Search Page';
  } else if (isSalesLeadList()) {
    return 'Sales Navigator Leads List';
  } else if (isRecruiterSearchPage()) {
    return 'Recruiter Search Page';
  } else if (isRecruiterPipeline()) {
    return 'Recruiter Pipeline Page';
  } else {
    return 'PAGE NOT HANDLED - investigate soon';
  }
}

// For tracking (aka extensionPage - event property)
// 01-28-2021: Currently disabled because we are reaching our daily limit
// https://app.asana.com/0/1199685488717575/1199686634427378
export function getLinkedinOverlayPage() {
  /**
   * There is another name called "More Settings"
   *  but it is only triggered in LinkedinOverlay.
   */
  if (isSearch()) {
    return 'Bulk Prospect';
  } else if (isProfile()) {
    return 'Single Prospect';
  } else {
    return 'Placeholder';
  }
}

export function isFeed() {
  return isLinkedinFeed() || isSalesNavFeed();
}

export function isSearch() {
  return (
    isNewSearch() ||
    isSalesNavigatorSearch() ||
    isCompanyPeople() ||
    isSalesLeadList() ||
    isRecruiterTalentSearch()
  );
}

export function isProfile(url = getCurrentUrl()) {
  return (
    isInProfile(url) ||
    isSalesNavigatorProfile(url) ||
    isPubProfile(url) ||
    isRecruiterPersonProfile(url) ||
    isRecruiterPersonProfilePopUp(url)
  );
}

export function isCompany(url = getCurrentUrl()) {
  return (
    isSalesNavCompany(url) ||
    url.toLowerCase().includes('linkedin.com/company/') ||
    url.toLowerCase().includes('linkedin.com/school/')
  );
}

export function isLinkedinFeed() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/feed');
}

export function isSalesNavFeed() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/sales/homepage');
}

export function isSalesNavCompany(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/sales/company');
}

export function isSalesNavigatorSearch() {
  return (
    getCurrentUrl().toLowerCase().includes('linkedin.com/sales/search') &&
    !getCurrentUrl().toLowerCase().includes('linkedin.com/sales/search/company')
  );
}

export function isSalesNavigatorSearchA() {
  return isSalesNavigatorSearch() && $('.search-results__result-list').length > 0;
}

// Newest Sales Navigator search page layout as of 01/04/2022
export function isSalesNavigatorSearchB() {
  return isSalesNavigatorSearch() && $('ol.artdeco-list[class*=search-results]').length > 0;
}

// Newest Sales Navigator search page layout as of 11/15/2023
export function isSalesNavigatorSearchC() {
  return isSalesNavigatorSearch() && $('[data-sn-view-name="lead-search-results"]').length > 0;
}

export function isNewSearch() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/');
}

export function isIndexSearch() {
  return (
    getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/index') ||
    getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/all')
  );
}

export function isPeopleSearch() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/people');
}

export function isSalesNavigatorProfile(url = getCurrentUrl()) {
  return (
    url.toLowerCase().includes('linkedin.com/sales/profile') ||
    url.toLowerCase().includes('linkedin.com/sales/people') ||
    url.toLowerCase().includes('linkedin.com/sales/lead')
  );
}

export function isInProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/in/');
}

export function isProfileD() {
  return isInProfile() && $('.pv-top-card-section__name').length > 0;
}

export function isProfileF() {
  return isInProfile() && $('.pv-top-card--list .t-black').length > 0;
}

export function isProfileH() {
  return isInProfile() && $('.pv-text-details__left-panel').length > 0;
}

export function isProfileI() {
  return isInProfile() && $('section.pv-top-card h1.text-heading-xlarge').length > 0;
}

export function isPubProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/pub/');
}

export function isCompanyPeople(url = getCurrentUrl()) {
  const regex = /linkedin.com\/company\/.+\/people/g;
  return regex.test(url);
}

export function isSalesLeadList() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/sales/lists/people/');
}

export function isRecruiterSearchPage() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/talent/search');
}

export function isRecruiterPipeline() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/talent/hire');
}

export function isRecruiterSearch() {
  return isRecruiterSearchPage() || isRecruiterPipeline();
}

export function isRecruiterTalentSearch() {
  return isRecruiterSearch() && !isRecruiterPersonProfilePopUp();
}

export function isRecruiterPersonProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/talent/profile/');
}

export function isRecruiterPersonProfilePopUp(url = getCurrentUrl()) {
  return isRecruiterSearch() && url.toLowerCase().includes('/profile/');
}

function getOrganizationLinkedinUidFromUrl(linkedinUrl: string | null) {
  if (!linkedinUrl) {
    return;
  }

  return linkedinUrl.split('/company/')[1]?.split('/')[0]?.split('?')[0];
}

export function getInnerText(element: Element | null) {
  let text: string = '';
  try {
    if (element instanceof HTMLElement) {
      text = element.innerText.trim();
    }
  } catch (e) {
    // ignore error
  }

  return text;
}

export function obtainLinkedinPeopleJSONFromSearch() {
  const output: {
    name?: string | null;
    activeIndex?: number;
    [key: string]: unknown;
  }[] = [];
  let pageName = '';
  let fieldHtml: NodeListOf<Element> | Element | undefined | null;
  if (isCompanyPeople()) {
    let organizationLinkedinUid: string | undefined;

    const maybeOrgIdContainer = document.querySelector(
      '[data-control-name=topcard_overflow_share_page_button]',
    );
    if (maybeOrgIdContainer instanceof HTMLAnchorElement && maybeOrgIdContainer.href) {
      try {
        const url = new URL(maybeOrgIdContainer.href);
        organizationLinkedinUid = getOrganizationLinkedinUidFromUrl(url.searchParams.get('body'));
      } catch (e) {
        // ignore invalid url
      }
    }

    const newOrgIdContainer = document.querySelector('[href*="/search/results/people"]');
    if (newOrgIdContainer instanceof HTMLAnchorElement) {
      const url = new URL(newOrgIdContainer.href);

      if (url.searchParams.has('currentCompany')) {
        const currentCompany = url.searchParams.get('currentCompany');
        /**
         * * Sample url:
         * /search/results/people/?currentCompany=%5B%22165158%22%5D&origin=COMPANY_PAGE_CANNED_SEARCH
         *
         * * Actual org uid:
         * ...currentCompany=%5B%22----->165158<-----%22%5D&origin...
         *
         * The value of currentCompany is in the format ["123"] or [123], where 123 is the Org ID
         */
        organizationLinkedinUid = currentCompany?.split(/\["?/)[1]?.split(/"?\]/)[0];
      }
    }

    const items = document.querySelectorAll('.org-people-profile-card__profile-info');
    pageName = 'Company People';
    fieldHtml = items;
    items.forEach((element) => {
      const name = getInnerText(element.querySelector('.artdeco-entity-lockup__title'));
      const headline = getInnerText(element.querySelector('.lt-line-clamp--multi-line'));

      const link = element.querySelector('.link-without-visited-state');
      if (link instanceof HTMLAnchorElement) {
        const href = link?.href;

        const photo = element.querySelector('.artdeco-entity-lockup__image img');
        let photoUrl = null;

        if (photo instanceof HTMLImageElement) {
          photoUrl = photo?.src;
          photoUrl = !photoUrl || photoUrl.startsWith('data:') ? null : photoUrl;
        }

        output.push({
          href,
          name,
          headline,
          photoUrl,
          organizationLinkedinUid,
        });
      }
    });
  } else if (isSalesLeadList()) {
    pageName = 'Sales Lead List';
    fieldHtml = document.querySelectorAll('.artdeco-models-table-row');
    fieldHtml.forEach((element) => {
      const name = getInnerText(element.querySelector('[data-anonymize=person-name]'));

      const imageLink = element.querySelector('.lists-detail__view-profile-image-link');

      let href = null;
      if (imageLink instanceof HTMLAnchorElement) {
        href = imageLink?.href;
      }

      const title = getInnerText(element.querySelector('[data-anonymize=job-title]'));
      const presentRawAddress = getInnerText(element.querySelector('[data-anonymize=location]'));

      const image = element.querySelector('[data-anonymize=headshot-photo]');
      let photoUrl = null;
      if (image instanceof HTMLImageElement) {
        photoUrl = image.src;
        photoUrl = photoUrl.startsWith('data:') ? null : photoUrl;
      }

      let organizationName = getInnerText(element.querySelector('[data-anonymize=company-name]'));

      /** Examples: "Interco (+3)", "GL Enterprises , LLC (+2)" */
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (organizationName?.includes(' (+')) {
        organizationName = organizationName.split(' (+')[0]!;
      }

      output.push({
        href,
        name,
        title,
        presentRawAddress,
        photoUrl,
        organizationName,
      });
    });
  } else if (isRecruiterTalentSearch()) {
    pageName = 'Recruiter Talent Search';
    fieldHtml = document.querySelectorAll('.profile-list-item');
    fieldHtml.forEach((element) => {
      const link = element.querySelector('[data-test-link-to-profile-link]');
      let href = null;
      if (link instanceof HTMLAnchorElement) {
        href = link.href;
      }
      const name = getInnerText(element.querySelector('[data-test-row-lockup-full-name]'));
      let title;
      let organizationName;
      let headline;

      const experienceList = element.querySelectorAll('.history-group__list-items li');
      const currentExperience = experienceList[0];
      let latestCompanyText = getInnerText($(currentExperience).find('span')[0]);
      // Below Condition is required because in the new LI Recruiter it's showing the years of experience like
      // "7+ years of Software Engineer experience"
      if (
        experienceList.length > 1 &&
        $(currentExperience).find('span[data-test-description-entry-date-duration]').length === 0
      ) {
        latestCompanyText = getInnerText($(experienceList[1]).find('span')[0]);
      }
      const presentRawAddress = getInnerText(
        element.querySelector('[data-test-row-lockup-location]'),
      );

      const image = element.querySelector('[data-test-row-lockup-figure] img');
      let photoUrl = null;
      if (image instanceof HTMLImageElement) {
        photoUrl = image.src;
        photoUrl = photoUrl?.startsWith('data:') ? null : photoUrl;
      }

      if (latestCompanyText) {
        title = latestCompanyText?.split(' at ')[0]?.trim();
        organizationName = latestCompanyText.split(' at ')[1]?.trim();
        output.push({
          href,
          name,
          title,
          organizationName,
          presentRawAddress,
          photoUrl,
        });
      } else {
        headline = getInnerText(element.querySelector('[data-test-row-lockup-headline]'));
        output.push({
          href,
          name,
          headline,
          presentRawAddress,
          photoUrl,
        });
      }
    });
  } else if (isPeopleSearch() || isIndexSearch()) {
    pageName = 'People Search/Index Search';
    fieldHtml = document.querySelectorAll(NEW_SEARCH_PERSON_ROW_SELECTOR);
    fieldHtml.forEach((element) => {
      const hasPost = element.querySelector('.entity-result__content-inner-container');
      const hasPeopleLink = element.querySelector('[href*="/in/"]');

      /**
       * Only parse rows with people link and no post since LinkedIn's new layout uses
       *  common styles regardless of search result type (People, Job, Company, etc.)
       */
      if (!hasPost && hasPeopleLink) {
        const name = getInnerText(
          element.querySelector('.entity-result__title-text a.app-aware-link [aria-hidden="true"]'),
        );

        const headline = getInnerText(element.querySelector('.entity-result__primary-subtitle'));

        const presentRawAddress = getInnerText(
          element.querySelector('.entity-result__secondary-subtitle'),
        );

        const link = element.querySelector('.entity-result__title-text a.app-aware-link');
        let href;
        if (link instanceof HTMLAnchorElement) {
          href = link.href;
        }

        let photoUrl;
        let image = element.querySelector('.EntityPhoto-circle-3');
        if (image instanceof HTMLImageElement) {
          photoUrl = image.src;
        }

        if (!photoUrl) {
          // Not getting all EntityPhoto-circle since they might not be related to photoUrl
          image = element.querySelector('.EntityPhoto-circle-6');
          if (image instanceof HTMLImageElement) {
            photoUrl = image.src;
          }
        }

        /** No name with [aria-hidden="true"] == "LinkedIn Member" */
        output.push({
          href,
          name,
          headline,
          presentRawAddress,
          photoUrl,
        });
      }
    });
  } else {
    let activeIndex: number | undefined;
    let href: string | string[] | null | undefined;
    let name: string | undefined | null;
    let presentRawAddress: string | undefined;
    let photoUrl: string | string[] | undefined | null;
    let organizationName: string | undefined;
    let organizationLinkedinUid: string | undefined;
    let title: string | undefined;

    if (isSalesNavigatorSearchA()) {
      const resultContainerEl = document.querySelector('.search-results__result-list');
      pageName = 'Salesnavigator Search A';
      fieldHtml = resultContainerEl;
      resultContainerEl?.querySelectorAll('.search-results__result-item').forEach((element, i) => {
        const nameUrlEl = element.querySelector('.result-lockup__name > a');
        if (!nameUrlEl) {
          return;
        }

        activeIndex = undefined;
        const checkboxEl = element.querySelector('input[type=checkbox]');
        if (checkboxEl instanceof HTMLInputElement) {
          activeIndex = checkboxEl?.checked ? i : undefined;
        }

        href = nameUrlEl.getAttribute('href');
        href = href ? `https://www.linkedin.com${href}` : undefined;

        name = element.querySelector('.result-lockup__name')?.textContent?.trim() ?? undefined;

        const photoEl = element.querySelector('.result-lockup__icon-link img');
        photoUrl = photoEl ? photoEl.getAttribute('src') : undefined;

        const orgNameEl = element.querySelector('.result-lockup__position-company');
        organizationName = orgNameEl?.textContent?.trim() ?? undefined;

        let goTo: string | Element | null = orgNameEl
          ? orgNameEl.querySelector('.a11y-text')
          : null;
        goTo = goTo?.textContent?.trim() ?? null;

        if (organizationName && goTo && organizationName.includes(goTo)) {
          organizationName = organizationName?.split(goTo)?.[0]?.trim() ?? undefined;
        }

        let rawData;
        if (orgNameEl instanceof HTMLElement) {
          rawData = orgNameEl.dataset.entityHovercardId; // e.g. "urn:li:fs_salesCompany:57866"
        }

        organizationLinkedinUid = rawData?.split(':')?.[3] ?? undefined;

        const highlightEls = element.querySelectorAll('.result-lockup__highlight-keyword > span');
        const titleEl = Array.from(highlightEls).find(
          (el) => !el.classList.contains('result-lockup__position-company'),
        );
        title = titleEl?.textContent?.trim() ?? undefined;

        const miscItems = element.querySelectorAll('.result-lockup__misc-item');
        const addressEl = Array.from(miscItems).find((el) => el?.textContent?.includes(' Area'));
        presentRawAddress = addressEl?.textContent?.trim() ?? undefined;

        /** No checkbox in Sales Nav == not employed / we are unable to parse */
        if (checkboxEl) {
          output.push({
            activeIndex,
            href,
            name,
            presentRawAddress,
            organizationName,
            organizationLinkedinUid,
            title,
            photoUrl:
              photoUrl && !photoUrl.includes('ghosts/person/ghost_person') ? photoUrl : null,
          });
        }
      });

      return output.filter((item) => !isKeyofIsLinkedinMember(item.name));
    }

    let containerSelector = '';

    if (isSalesNavigatorSearchB()) {
      containerSelector = 'ol.artdeco-list[class*=search-results]';
    } else if (isSalesNavigatorSearchC()) {
      containerSelector = '[data-sn-view-name="lead-search-results"]';
    }

    if (containerSelector) {
      const salesNavigatorBContainer = document.querySelector(containerSelector);
      pageName = 'Salesnavigator Search B';
      fieldHtml = salesNavigatorBContainer?.querySelectorAll('li.artdeco-list__item');
      fieldHtml?.forEach((element) => {
        const nameUrlEl = element.querySelector('.artdeco-entity-lockup__title > a');

        const checkboxEl = element.querySelector('input[type=checkbox]');
        if (!nameUrlEl || !checkboxEl) {
          // If there is no link or checkbox, the lead is private or not loaded yet
          return;
        }

        href = nameUrlEl.getAttribute('href');
        if (href && !href.includes('linkedin.com')) {
          href = `https://www.linkedin.com${href}`;
        }

        name = element.querySelector('.artdeco-entity-lockup__title')?.textContent?.trim();

        photoUrl = element
          .querySelector('div[data-test-lead-result-entity=image] img')
          ?.getAttribute('src');

        if (!photoUrl) {
          photoUrl = element
            .querySelector('img[data-anonymize=headshot-photo]')
            ?.getAttribute('src');
        }

        const orgNameEl = element.querySelector(
          '.artdeco-entity-lockup__subtitle > a[data-anonymize=company-name]',
        );
        organizationName = orgNameEl?.textContent?.trim();

        if (!organizationName) {
          element.querySelector('.artdeco-entity-lockup__subtitle')?.childNodes.forEach((el) => {
            if (
              el.nodeType === Node.TEXT_NODE &&
              el.textContent?.replaceAll(/\n/g, '').trim() !== ''
            ) {
              organizationName = el.textContent?.trim();
            }
          });
        }

        const orgHref =
          orgNameEl?.getAttribute('data-savepage-href') ?? orgNameEl?.getAttribute('href') ?? '';
        const orgHrefArr = orgHref.split('?');
        const orgPath = orgHrefArr[0]; // href minus the parameters, ex: "/sales/company/33194934"
        organizationLinkedinUid =
          orgPath && orgPath.length > 0 ? orgPath?.substring(15) : undefined; // substring removes "/sales/company/"

        // There are two child spans - the first one is a separator--middot, the other is the actual title with no attributes
        title = element
          .querySelector('.artdeco-entity-lockup__subtitle > span:last-child')
          ?.textContent?.trim();

        if (!title) {
          // Similar layout here except the title comes first and then the separator
          title = element
            .querySelector('[data-test-lead-result-entity=title-at-company] span:first-child')
            ?.textContent?.trim();
        }

        if (!title) {
          // Same format above but different DOM
          title = element
            .querySelector('[class*=artdeco-entity-lockup__subtitle] :first-child')
            ?.textContent?.trim();
        }

        presentRawAddress = element
          .querySelector('.artdeco-entity-lockup__caption > span')
          ?.textContent?.trim();

        output.push({
          href,
          name,
          photoUrl,
          organizationName,
          organizationLinkedinUid,
          title,
          presentRawAddress,
        });
      });
    }
  }
  const emptyKeys: string[] = [];
  const count: { [key: string]: number } = {};
  type KeyType = {
    [key: string]: unknown;
  };
  // obtaining all empty keys from Output array.
  let tempOutput: KeyType[] = cloneDeep(output);
  tempOutput = tempOutput.map((key: KeyType) => {
    const updatedKey = { ...key };
    delete updatedKey.organizationLinkedinUid;
    delete updatedKey.href;
    delete updatedKey.photoUrl;
    return updatedKey;
  });

  tempOutput.map((item) => {
    Object.keys(item).map((key) => {
      if (!item[key] && key !== 'href' && key !== 'photourl') {
        emptyKeys.push(key);
      }
    });
  });
  // counting the repeted keys value
  emptyKeys.forEach((i) => {
    count[i] = (count[i] ?? 0) + 1;
  });
  // checking if any of the repeated key is equal to the main array length,
  // if so then sending sentry error.
  Object.keys(count).map((finalItem) => {
    if (count[finalItem] === tempOutput.length) {
      let extraFieldHtml: Element[] = [];
      if (fieldHtml instanceof Array) {
        extraFieldHtml = fieldHtml;
      }
      logExtensionErrorToSentry(
        {
          error: '[BD] Error while parsing the html in ' + pageName,
          extraFields: {
            fieldHtml: Array.from(extraFieldHtml).map((element: Element) => element.innerHTML),
            finalItem,
            pageName,
          },
        },
        //do not remove the second parameter,
        // it's being used for throttling and will impact huge on sentry if removed without making changes in core sentry event
        {
          pageName,
          finalItem: [finalItem],
        },
      );
    }
  });
  return output.filter((item) => {
    return !isKeyofIsLinkedinMember(item.name);
  });
}

/** For new regular LI layout */
export function shouldRenderBulkSelect(newInjectedElements?: boolean) {
  if (newInjectedElements) {
    return false;
  }
  const newSearchPeopleRows = $(NEW_SEARCH_PERSON_ROW_SELECTOR);
  const newSearchPeopleRowsArr = Array.from(newSearchPeopleRows);

  /** Do not render if title-bars exist like Company / Job / School / etc. */
  if (!isEmpty($('.search-results__cluster-title-bar')) && !isPeopleSearch()) {
    return false;
  }

  /** Ensures there are no Company / Job / School / etc. rows */
  const everyRowHasPeopleLink = newSearchPeopleRowsArr.every((element) => {
    const name = $(element).find('.entity-result__title-text').text().trim();

    /**
     * Count "LinkedIn Member" row names as People since in the new layout
     *  sometimes the row does not have a people link and clicking on the name
     *  will open a popup saying "You don't have access to this profile". This popup
     *  shows when the profile is private and you don't have any mutual connections.
     *
     * The second check is similar to "LinkedIn Member". It's a CTA for upgrading to Premium.
     *  We don't parse it, but we want the bulk select injection to continue if it exists.
     */
    return (
      isKeyofIsLinkedinMember(name) ||
      $(element).find('.card-upsell__cta-link').length > 0 ||
      $(element).find('.artdeco-button--premium').length > 0 ||
      $(element).find('[href*="/in/"]').length > 0
    );
  });

  /** Don't render bulk select if one row has a post (posts contain people links) */
  const oneRowHasPost = newSearchPeopleRowsArr.some((element) => {
    return $(element).find('.entity-result__content-inner-container').length > 0;
  });

  return !oneRowHasPost && everyRowHasPeopleLink;
}

export function shouldInjectBulkSelect() {
  return (
    isPeopleSearch() ||
    (isIndexSearch() && shouldRenderBulkSelect()) ||
    isCompanyPeople() ||
    isSalesNavigatorSearch() ||
    isSalesLeadList() ||
    isRecruiterTalentSearch()
  );
}

export function hasBulkSelectPanel() {
  return (
    !isEmpty($('.linkedin-results-select-all-button')) ||
    !isEmpty($('.company-people-bulk-select')) ||
    !isEmpty($('.leads-list-bulk-select'))
  );
}

export function shouldInjectBulkSelectElements(
  currentTeam: Team | undefined,
  linkedinSearchRemoveButtons: boolean,
  extensionLinkedinInjectedElements?: boolean,
) {
  if (!currentTeam) {
    return false;
  }

  if (extensionLinkedinInjectedElements) {
    return true;
  }

  const splitTestCutoffDate = '2024-03-04T12:16:20.717Z';

  const isTeamCreatedBeforeCutoffDate = moment(currentTeam.createdAt).isBefore(splitTestCutoffDate);

  const isPaidTeam = isTeamPaying(currentTeam);
  if (currentTeam?.canAccessBulkSelectOnExtension && !linkedinSearchRemoveButtons) {
    return true;
  } else if (isTeamCreatedBeforeCutoffDate && !linkedinSearchRemoveButtons && isPaidTeam) {
    return true;
  } else if (linkedinSearchRemoveButtons || !isPaidTeam) {
    return false;
  } else if (currentTeam?.canAccessBulkSelectOnExtension) {
    return true;
  } else {
    return false;
  }
}
