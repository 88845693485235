import React from 'react';
import * as Sentry from '@sentry/react';
import { last } from 'lodash-es';
import { isWindowMode } from 'chrome-extension/inject/core/lib/locationUtils';
import { isExtensionServiceWorker } from 'chrome-extension/inject/core/lib/utils';

import { EXTERNAL_SCRIPT_LOAD_ERROR_MESSAGE } from 'sentry/ignore';
import type { ReduxDispatch } from '$types/redux';

export function handlePopupOrRedirect(redirectUri: string | Location | URL) {
  if (isWindowMode()) {
    window.open(
      redirectUri,
      'Apollo',
      'width=800,height=900,toolbar=no,resizable=no,status=no,titlebar=no,top=50,left=50,menubar=no',
    );
  } else {
    window.location = redirectUri;
  }
}

/**
 * Returns a promise that resolves after `timeout`.
 * @param [timeout] Timeout in milliseconds (Default: 1000 ms).
 * @param {object} [options]
 * @param {AbortSignal} [options.signal] Abort Signal
 *
 */
export function delay(timeout = 1000, options: { signal?: AbortSignal } = {}): Promise<unknown> {
  return new Promise((resolve, reject) => {
    let timeoutId: string | number | ReturnType<typeof setTimeout>;
    const signal = options.signal instanceof AbortSignal ? options.signal : null;

    function onAborted() {
      clearTimeout(timeoutId);
      reject(signal.reason);
    }

    function onResolved() {
      if (signal) {
        signal.removeEventListener('abort', onAborted);
      }
      resolve();
    }

    if (signal && signal.aborted) {
      onAborted();
      return;
    }

    if (signal) {
      signal.addEventListener('abort', onAborted, { once: true });
    }

    timeoutId = setTimeout(onResolved, timeout);
  });
}

/**
 * Returns a promise that resolves when the function `fn` returns a truthy value.
 * Promise rejects if it fails after trying the maximum retries.
 *
 * @param {function} fn
 * @param {object} options
 * @param {number} [options.interval] Interval between each fn() call (Default: 1000)
 * @param {number} [options.maxRetries] Maximum retries (Default: 10)
 * @param {AbortSignal} [options.signal] Abort signal
 *
 */
export async function waitForFn(
  fn: (...args: unknown[]) => unknown,
  options: { interval?: number; maxRetries?: number; signal?: AbortSignal } = {},
): Promise<unknown> {
  const { interval = 1000, maxRetries = 10 } = options;
  const signal = options.signal instanceof AbortSignal ? options.signal : null;
  let intervalId: string | number | ReturnType<typeof setInterval>;
  let retryCount = 0;
  return new Promise((resolve, reject) => {
    function onResolve(result: unknown) {
      clearInterval(intervalId);
      if (signal) {
        signal.removeEventListener('abort', onAborted);
      }
      resolve(result);
    }

    function onReject(error: Error) {
      clearInterval(intervalId);
      if (signal) {
        signal.removeEventListener('abort', onAborted);
      }
      reject(error);
    }

    function onAborted() {
      onReject(signal.reason);
    }

    if (signal) {
      signal.addEventListener('abort', onAborted, { once: true });
    }

    function handler() {
      if (signal && signal.aborted) {
        onAborted();
        return;
      }
      if (retryCount < maxRetries) {
        try {
          const result = fn();
          if (result) {
            onResolve(result);
          }
          retryCount += 1;
        } catch (e) {
          onReject(e);
        }
      } else {
        onReject(new Error(`waitForFn timed out after ${maxRetries} retries.`));
      }
    }

    handler();

    intervalId = setInterval(handler, interval);
  });
}

/**
 * @param {AbortSignal} [signal]
 */
export function throwIfAborted(signal: AbortSignal) {
  // AbortSignal.throwIfAborted() may be used if it's widely supported at the time of reading this.
  if (signal && signal.aborted) {
    throw signal.reason;
  }
}

export const isStagingOrLocal =
  process.env.IS_STAGING || process.env.IS_DEVELOPMENT || process.env.IS_TEST;

/**
 * @param {Error} e
 *
 */
export function isJSONParsingError(e: Error): boolean {
  // Chrome: SyntaxError: Unexpected end of JSON input
  // Firefox: SyntaxError: JSON.parse: unexpected keyword at line 1 column 1 of the JSON data
  // Safari: JSON Parse error: Unexpected identifier "t"
  // Safari (fetch): SyntaxError: The string did not match the expected pattern
  return /json/i.test(e.message) || /string did not match/i.test(e.message);
}

export function isHTMLContent(content: string | string[]) {
  if (typeof content !== 'string') {
    return false;
  }

  return ['<html', '<body', '<head', '<title'].some((tag) => content.includes(tag));
}

/**
 * Returns the innerText from an HTML string.
 * Warning: Line breaks are not preserved.
 *
 * @param {string} html
 *
 */
export function getInnerText(html: string): string {
  if (typeof html !== 'string') {
    return '';
  }

  const domParser = new DOMParser();
  return domParser.parseFromString(html, 'text/html').documentElement.innerText;
}

/**
 * Converts HTML to plain text.
 * Plain text is innerText where line breaks are preserved or as rendered in a browser.
 *
 * Test link: https://natto.dev/@sidapollo/d175be71b6714e738b32175c571a9f29
 *
 * @param {string} html
 * @param {object} [options]
 * @param {boolean} [options.preventDoubleConversion] Returns the input without conversion if input is already in plaintext
 *
 */
export function getPlainText(
  html: string,
  { preventDoubleConversion = true }: { preventDoubleConversion?: boolean } = {},
): string {
  if (typeof html !== 'string') {
    return '';
  }

  const domParser = new DOMParser();
  const parsedDocument = domParser.parseFromString(html, 'text/html');

  if (preventDoubleConversion && parsedDocument.body.childElementCount === 0) {
    return html;
  }

  const { documentElement } = parsedDocument;

  // Remove internal whitespace <div>Text   </div> to <div>Text</div>
  const walker = document.createTreeWalker(documentElement, NodeFilter.SHOW_ALL);
  let current = null;
  // eslint-disable-next-line no-cond-assign
  while ((current = walker.nextNode())) {
    if (current.parentNode) {
      current.parentNode.innerHTML = current.parentNode.innerHTML.trim().replaceAll('\n', '');
    }
  }

  // Explicity add "\n" for elements which introduce a line break
  Array.from(documentElement.querySelectorAll('br,div,p')).forEach((node) => {
    if (node.nodeName === 'BR' && node.parentElement && node.parentElement.lastChild === node) {
      // For "<div><br/><div>" the renderer considers one line break so avoid adding "\n"
    } else {
      node.insertAdjacentText('afterend', '\n');
    }
  });

  return documentElement.innerText;
}

// Copied requestIdleCallback and cancelIdleCallback from https://github.com/pladaria/requestidlecallback-polyfill/blob/master/index.js

export const requestIdleCallback =
  (!isExtensionServiceWorker && window.requestIdleCallback) ||
  ((cb) => {
    const start = Date.now();
    return Number(
      setTimeout(() => {
        cb({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1),
    );
  });

export const cancelIdleCallback =
  window.cancelIdleCallback ||
  ((id: number) => {
    clearTimeout(id);
  });

/**
 * Returns the callback function wrapped inside requestIdleCallback.
 * Useful to perform background and low priority work on the main event loop,
 * without impacting latency-critical events such as animation and input response.
 *
 * @param {function} callback Callback
 * @param {number} timeout Maximum wait time in milliseconds. The callback will be executed after `timeout` milliseconds
 * if not already (even if doing so risks causing a negative performance impact).
 *
 */
export function getLowPriorityCallback(
  callback: (...args: unknown[]) => unknown,
  timeout: number = 4000,
) {
  return function runOnLowPriority() {
    // eslint-disable-next-line prefer-spread
    requestIdleCallback(() => callback.apply(null, arguments), { timeout });
  };
}

/**
 * Simulates input into an element. Returns true if it succeeds.
 *
 * @param {HTMLElement} element
 * @param {string} content
 *
 */
export async function simulateInput(element: HTMLElement, content: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    if (!element) {
      reject(new Error('Element missing while simulating input.'));
      return;
    }

    setTimeout(() => {
      element.focus();
      document.execCommand('insertText', false, content);
      resolve();
    });
  });
}

/**
 * Simulates clearing an input element. Returns true if it succeeds.
 *
 * @param {HTMLElement} element
 *
 */
export async function simulateClearInput(element: HTMLElement): Promise<unknown> {
  return new Promise((resolve, reject) => {
    if (!element) {
      reject(new Error('Element missing while simulating clear input.'));
      return;
    }

    setTimeout(() => {
      element.focus();
      document.execCommand('selectAll');
      document.execCommand('delete');
      resolve();
    });
  });
}

export async function waitForHeadTag() {
  return waitForFn(() => document.head, { interval: 100, maxRetries: 100 });
}

/**
 *
 * @param error
 *
 */
export function errorToJSON(error): { stack: string; name: string | string; message: string } | {} {
  if (error && typeof error === 'object') {
    return {
      name: error.name || '',
      message: error.message || '',
      stack: error.stack || '',
    };
  }

  return {};
}

export function focusElement(element: { focus: () => void }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (typeof element?.focus === 'function') {
        element.focus();
        resolve();
      } else {
        reject(new Error('Failed to focus on the element.'));
      }
    });
  });
}

export async function waitForDocumentToBeReady() {
  function isComplete() {
    return document.readyState === 'complete';
  }

  return new Promise((resolve) => {
    if (isComplete()) {
      resolve();
      return;
    }

    document.addEventListener(
      'readystatechange',
      function listener() {
        if (isComplete()) {
          document.removeEventListener('readystatechange', listener);
          resolve();
        }
      },
      true,
    );
  });
}

export async function waitForDocumentToBeInteractive() {
  function isInteractive() {
    return document.readyState === 'complete' || document.readyState === 'interactive';
  }

  return new Promise((resolve) => {
    if (isInteractive()) {
      resolve();
      return;
    }

    document.addEventListener(
      'readystatechange',
      function listener() {
        if (isInteractive()) {
          document.removeEventListener('readystatechange', listener);
          resolve();
        }
      },
      true,
    );
  });
}

/**
 *
 * [
 *   { id: 1, value: 'One' },
 *   { id: 2, value: 'Two' },
 *   { id: 3, value: 'Three' },
 * ];
 *
 * to
 * [
 *   ['id', 'value'],
 *   [1, 'One'],
 *   [2, 'Two'],
 *   [3, 'Three'],
 * ]
 *
 * @param {Array<Object>} arrayOfObjects
 */
export function transformArrayOfObjectsToTable(arrayOfObjects: object[]): unknown[][] {
  const headersSet = new Set();
  arrayOfObjects.forEach((object) => {
    Object.keys(object).forEach((key) => headersSet.add(key));
  });

  const headers = Array.from(headersSet);
  const rows = [];
  arrayOfObjects.forEach((object, objectIndex) => {
    rows[objectIndex] = headers.map((header) => {
      return object[header];
    });
  });

  return [headers].concat(rows);
}

/**
 * Runs the function inside a try-catch block and reports error to Sentry
 */
export function trySafely(fn: () => unknown, options = { reportError: true }) {
  try {
    return fn();
  } catch (e) {
    console.error('Non-fatal error:', e);
    if (options.reportError) {
      Sentry.captureException(e);
    }
  }
}

/**
 * Handles errors by either throwing them in non-production environments or reporting them to Sentry in production or test environments.
 */
export function errorSafely(params: { message: string } | { error: Error }) {
  const errorToThrow = 'error' in params ? params.error : new Error(params.message);

  if (process.env.IS_PRODUCTION || process.env.IS_TEST || process.env.IS_CYPRESS_TEST) {
    Sentry.captureException(errorToThrow);
  } else {
    throw errorToThrow;
  }
}

export function loadExternalScript(source: string): Promise<void> {
  if (process.env.IS_EXTENSION) {
    // Loading external scripts is NOT supported on the Extension. This needs to be tree-shaken.
    return Promise.reject(new Error('loadExternalScript is not supported on the Extension.'));
  }

  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${source}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = source;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = (e) => {
      const error = new Error(EXTERNAL_SCRIPT_LOAD_ERROR_MESSAGE, {
        cause: e,
      });

      reject(error);
    };

    document.body.appendChild(script);
  });
}

export async function loadCloudflareTurnstileScript(): Promise<void> {
  if (process.env.IS_EXTENSION) {
    // Loading external scripts is NOT supported on the Extension. This needs to be tree-shaken.
    return Promise.reject(
      new Error('loadCloudflareTurnstileScript is not supported on the Extension.'),
    );
  }
  return loadExternalScript('https://challenges.cloudflare.com/turnstile/v0/api.js');
}

export function createResource<T>(promise: Promise<T>): { read: () => T } {
  let status: 'pending' | 'success' | 'error' = 'pending';
  let result: T | null = null;

  const suspender = promise.then(
    (response) => {
      status = 'success';
      result = response;
    },
    (error) => {
      status = 'error';
      result = error;
    },
  );

  return {
    read() {
      if (status === 'pending') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw suspender;
      } else if (status === 'error') {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw result;
      } else if (status === 'success') {
        return result as T;
      }
    },
  };
}

//Example usage: https://github.com/pavanmehta91/my-goto-js-utils#2-async-wrap
export function asyncWrap(promise) {
  return promise.then((result) => [null, result]).catch((err) => [err]);
}

export function pressKey(element: HTMLElement, details: KeyboardEventInit) {
  element.dispatchEvent(new KeyboardEvent('keydown', details));
  element.dispatchEvent(new KeyboardEvent('keyup', details));
}

// https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
export function mergeRefs<T = unknown>(
  refs: React.MutableRefObject<T>[] | React.LegacyRef<T>[],
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
/*
Useful for when you want to keep your internal
ref in sync with an optional forwarded ref.

Context here: https://stackoverflow.com/questions/73015696/whats-the-difference-between-reacts-forwardedref-and-refobject
*/
export function useForwardedRef<T>(ref: React.ForwardedRef<T>) {
  const innerRef = React.useRef<T>(null);

  React.useEffect(() => {
    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      /* eslint-disable-next-line no-param-reassign */
      ref.current = innerRef.current;
    }
  });

  return innerRef;
}

// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullscreen#examples
export function enterFullScreen(element: HTMLElement) {
  if (document.fullscreenEnabled) {
    element.requestFullscreen().catch((err) => {
      Sentry.captureException(err);
      alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
    });
  }
}

// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Document/exitFullscreen
export function exitFullScreen() {
  //if there's a fullscreen Element already
  if (document.fullscreenElement) {
    document.exitFullscreen().catch((err) => {
      Sentry.captureException(err);
      console.error(err);
    });
  }
}
/*
  A promise that resolves with `data` after `timeout`
  Use-case:
    `Gmail`: getDraftID call wait infinitely, and this is an issue if the draftID is never generated
    we use this util to race between the two promises and wait for `timeout` max.
*/
export function resolveAfterTimeout(data = null, timeout: number = 500) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout, data);
  });
}

export function getAccessbilityLabel(element: HTMLElement): string | null {
  const ariaLabel = element.getAttribute('aria-label');
  const ariaLabelledBy = element.getAttribute('aria-labelledby');

  if (ariaLabel) {
    return ariaLabel;
  } else if (ariaLabelledBy) {
    const labelledByElement = document.getElementById(ariaLabelledBy);
    if (labelledByElement) {
      return labelledByElement.textContent;
    }
  }

  return element.textContent;
}

export const getDomain = (email: string) => {
  if (!email) {
    return null;
  }
  return last(email.split('@'));
};

/*
  A base class that can be extended to support BatchProcessing of redux actions after timeout.
  This class provides the foundations whereby events within timeoutInSeconds can be batched and flushed at once to redux
*/
export class BatchProcessReduxActionsBase {
  timeoutDuration: number;
  runUnderLowPriorityCallback: boolean;
  timeOutKeeper: ReturnType<typeof setTimeout> | null;
  dispatch: ReduxDispatch | null = null;

  constructor(timeoutInSeconds: number = 5, runUnderLowPriorityCallback = true) {
    this.timeoutDuration = timeoutInSeconds * 1000;
    this.timeOutKeeper = null;
    this.runUnderLowPriorityCallback = runUnderLowPriorityCallback;
  }

  clearTimeoutKeeper = () => {
    if (this.timeOutKeeper) {
      clearTimeout(this.timeOutKeeper);
      this.timeOutKeeper = null;
    }
  };

  throttleAndBatchProcess = (callback: () => void) => {
    if (!this.timeOutKeeper) {
      this.timeOutKeeper = setTimeout(() => {
        if (typeof this.dispatch !== 'function') {
          console.error('BatchProcessReduxActions: dispatch must be function');
          Sentry.captureException(
            new Error('[FE] BatchEsIndexingJobActions could not dispatch redux action'),
          );
          return;
        }

        if (this.runUnderLowPriorityCallback) {
          getLowPriorityCallback(callback, 10)();
        } else {
          callback();
        }
      }, this.timeoutDuration);
    }
  };
}
