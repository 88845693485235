export const WISTIA_VIDEO_ERROR_MESSAGE = 'Error in Wistia video element';
export const EXTERNAL_SCRIPT_LOAD_ERROR_MESSAGE = 'Failed to load external script';

export const ignoreList = [
  'React DOM tree root should always have a node reference',
  'Invariant Violation',
  'findDOMNode was called on an unmounted component',
  '[object Object]',
  /^.*(Invariant Violation).*$/,
  /^.*(Unexpected token).*(in JSON at position).*$/,
  'Unexpected end of JSON input',
  'The node to be removed is not a child of this node',
  "Cannot read property 'getHostNode' of null",
  "Cannot read property 'appendChild' of null",
  "Cannot read property 'appendChild' of undefined",
  'tinymce.min.js',
  'ChunkLoadError: Loading chunk',
  'Error: Loading CSS chunk',
  'Non-Error exception captured with keys',
  // 'Maximum call stack size exceeded',
  'Object Not Found Matching Id',
  'https://zenprospecthelp.zendesk.com',
  '_promiseRejectionHandler',
  'The operation could not be performed and was aborted',
  'js.driftt.com',
  'removeChild', // Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.
  "Cannot read property 'messageType' of undefined",
  /^.*(Blocked a frame with origin).*(from accessing a cross-origin frame).*$/,
  /^.*(The play\(\) request was interrupted by a call to pause\(\)).*$/,
  'handleInputBlur',
  'Invalid pointer',
  "Failed to execute 'setEnd' on 'Range'",
  'promiseRejectionHandler',
  'Message recieved in closed window',
  'Unable to find node on an unmounted component',
  /^.*(Failed to fetch).*$/,
  /^.*(The offset .+ is larger than the node's length).*$/,
  /^.*(twilio\.js 1\.3 requires WebRTC\/ORTC browser support).*$/,
  /^.*(React DOM tree root should always have a node reference).*$/,
  /^.*(Invariant Violation).*$/,
  /^.*(Unexpected token).*(in JSON at position).*$/,
  /^.*(Unexpected end of JSON input).*$/,
  /^.*(Cannot read property 'getHostNode' of null).*$/,
  /^.*(Cannot read property 'appendChild' of null).*$/,
  /^.*(Cannot read property 'appendChild' of undefined).*$/,
  /^.*(tinymce\.min\.js).*$/,
  // /^.*(Maximum call stack size exceeded).*$/,
  /^.*(Cannot read property 'messageType' of undefined).*$/,
  /^.*(Blocked a frame with origin).*(from accessing a cross-origin frame).*$/,
  /^.*(The play\(\) request was interrupted by a call to pause\(\)).*$/,
  /^.*(handleInputBlur).*$/,
  /^.*(_promiseRejectionHandler).*$/,
  /^.*(twilio\.js 1\.3 requires WebRTC\/ORTC browser support).*$/,
  // https://developer.mozilla.org/en-US/docs/Web/API/AbortController
  // An AbortError is created when the UI determines that it no longer needs
  // a previous request. So, we don't care when it happens.
  //
  // Note: This should be caught in apiMiddleware's catch handler. This is
  // what happens locally and in most instances on prod.
  // Not sure why it doesn't work otherwise.
  'AbortError',
  'Failed to find Chrome runtime',
  'The Internet connection appears to be offline',
  /^.*(Cannot set properties of null \(setting 'chrome'\)).*$/,
  'FED :: Action Site URL and Success Page URL Not Match.',
  /Failed to execute.*on 'IDBTransaction'/,
  /ResizeObserver loop completed with undelivered notifications\./,
  'Not implemented on this platform', // https://github.com/WordPress/openverse/issues/524,
  'Attempt to register when device is in state "registered". Must be "unregistered".', // https://github.com/twilio/twilio-voice.js/issues/33
  'Cannot define property decorate', // https://github.com/apolloio/leadgenie/pull/24171
  'Non-Error promise rejection captured with value: undefined', // https://github.com/apolloio/leadgenie/pull/26000#issuecomment-1931975619
  'Cannot redefine property: googletag',
  WISTIA_VIDEO_ERROR_MESSAGE, // Drop video errors for for Wistia
  EXTERNAL_SCRIPT_LOAD_ERROR_MESSAGE, // Drop errors for external script load
  'DatabaseClosedError', // Sprig errors: https://apolloio.slack.com/archives/C02QKQKHGJW/p1709140109042859

  //Failed to fetch dynamically imported module:
  'https://fast.wistia.com/assets/external', //https://apollopde.atlassian.net/browse/INCIDENT-9638
];

export function shouldIgnoreError(error: Error) {
  // Drop "Event" errors from Wistia <video> elements
  if (error instanceof Event) {
    const target = error.target as HTMLElement | undefined;
    if (target?.nodeName === 'VIDEO' && target.id.startsWith('wistia_')) {
      return true;
    }
  }

  // Ignore parsing errors from hubspot
  // See https://apolloio.slack.com/archives/C0135F4D8TH/p1721151493223889?thread_ts=1721113391.206069&cid=C0135F4D8TH
  const { stack, message } = error;
  if (stack?.includes('https://js.hsadspixel.net/fb.js') && message.includes('Unexpected token')) {
    return true;
  }

  // Ignore errors in sharing-manage-access.spec
  // See https://apolloio.slack.com/archives/C0635FTL8DP/p1723238131203619
  const possibleSources = [
    'https://js.hubspot.com/web-interactives-embed.js',
    'https://js.intercomcdn.com/frame-modern',
  ];
  const errorMessage = 'Cannot read properties of null';
  if (possibleSources.some((path) => stack?.includes(path) && message?.includes(errorMessage))) {
    return true;
  }

  return false;
}
