// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_mE7no{background-color:var(--color-bg-secondary);color:var(--color-text-body);display:inline-flex;justify-content:center;align-items:center;gap:4px}.zp_mE7no i.zp_ko0yw{color:var(--color-text-body)}.zp_mE7no[data-count-size=small]{font-size:12px;font-weight:500;line-height:16px;padding:1px 4px;min-width:16px;min-height:16px;border-radius:6px}.zp_mE7no[data-count-size=medium]{font-size:12px;font-weight:500;line-height:16px;padding:1px 4px;min-width:20px;min-height:20px;border-radius:var(--border-radius-8)}.zp_mE7no[data-count-size=large]{font-size:14px;font-weight:500;line-height:20px;padding:3px 8px;min-width:24px;min-height:24px;border-radius:var(--border-radius-8)}.zp_mE7no.zp_uvtoz{color:var(--color-text-disabled)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": "zp_mE7no",
	"icon": "zp_ko0yw",
	"disabled": "zp_uvtoz"
};
export default ___CSS_LOADER_EXPORT___;
