// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_up66v.zp_fABCz{display:flex;align-items:center;justify-content:center;-moz-column-gap:8px;column-gap:8px}.zp_up66v .zp_BWgcT{height:8px;width:8px;padding:0;cursor:pointer;border:none;border-radius:var(--border-radius-8);background-color:var(--color-neutral-40)}.zp_up66v .zp_BWgcT.zp_WGn8d{outline:none}.zp_up66v .zp_BWgcT:focus-visible{outline:none}.zp_up66v .zp_BWgcT[data-focus-visible=true]{outline:none;box-shadow:0px 0px 0px 1px var(--color-ui-primary);border:1px solid var(--color-bg-main)}.zp_up66v .zp_BWgcT:hover{background-color:var(--color-neutral-50)}.zp_up66v .zp_BWgcT[data-pressed=true]{background-color:var(--color-neutral-60)}.zp_up66v .zp_BWgcT:disabled{background-color:var(--color-neutral-30);cursor:not-allowed}.zp_up66v .zp_BWgcT[aria-pressed=true]{width:20px;cursor:auto;background-color:var(--color-ui-primary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationDots": "zp_up66v",
	"group": "zp_fABCz",
	"dot": "zp_BWgcT",
	"focus-visible": "zp_WGn8d"
};
export default ___CSS_LOADER_EXPORT___;
