// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_xOlPp{width:420px;min-width:420px}.zp_Sbra5{font-size:14px;font-weight:500;line-height:20px;color:var(--color-text-primary);overflow:auto;white-space:pre-wrap}.zp_piYym{color:var(--color-caution-40);line-height:normal;margin-right:12px;font-size:22px}.zp_bL6rz{border-bottom:1px solid var(--color-border-light)}.zp_GYCLZ{display:flex;align-items:center}.zp_EM9uI{display:flex;justify-content:flex-end;border-top:none;padding:10px 10px 10px 20px}.zp_qEnWx{color:inherit}.zp_L3YT1{font-size:16px;font-weight:600;line-height:20px;color:var(--color-text-secondary)}.zp_LwbYf{font-size:14px;font-weight:500;line-height:20px;color:var(--color-text-secondary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertModal": "zp_xOlPp",
	"alertBody": "zp_Sbra5",
	"alertIcon": "zp_piYym",
	"modalHeader": "zp_bL6rz",
	"modalTitle": "zp_GYCLZ",
	"modalFooter": "zp_EM9uI",
	"alertLink": "zp_qEnWx",
	"bodyMessage": "zp_L3YT1",
	"supportText": "zp_LwbYf"
};
export default ___CSS_LOADER_EXPORT___;
