import React, { Suspense, type CSSProperties } from 'react';

const Player = React.lazy(() =>
  import('@lottiefiles/react-lottie-player').then((module) => ({ default: module.Player })),
);

const EMPTY_LOTTIE = {
  assets: [],
  layers: [],
  markers: [],
};

type LottiePlayerProps = {
  autoplay?: boolean;
  loop?: boolean;
  src?: object;
  fallback?: React.ReactNode;
  importer?: (...args: unknown[]) => Promise<{ default: object | string }>;
  style?: CSSProperties;
  keepLastFrame?: boolean;
  className?: string;
};

type LottieErrorBoundaryProps = {
  children: React.ReactNode;
};

class LottieErrorBoundary extends React.Component<LottieErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return null;
    }

    return this.props.children;
  }
}
export function LottiePlayer(props: LottiePlayerProps) {
  const {
    autoplay = true,
    loop = true,
    src,
    importer,
    fallback = null,
    style,
    keepLastFrame = false,
    className,
  } = props;
  const srcToUse = useLottieSource({ src, importer });

  return (
    <LottieErrorBoundary>
      <Suspense fallback={fallback ?? <span>Oops, failed to load image</span>}>
        <Player
          autoplay={autoplay}
          loop={loop}
          src={srcToUse}
          style={style}
          keepLastFrame={keepLastFrame}
          className={className}
        />
      </Suspense>
    </LottieErrorBoundary>
  );
}

/**
 * @param {Object} param0
 * @param {Object|string} [param0.src]
 * @param {Function} [param0.importer]
 *
 *
 */
function useLottieSource({
  src,
  importer,
}: {
  src?: object | string;
  importer?: (...args: unknown[]) => Promise<{ default: object | string }>;
}): object | string {
  /**
   * EMPTY_LOTTIE is the default source, because
   * we cannot render <Player /> without a source.
   *
   * If we delay loading <Player /> until we have a source,
   * then the dynamic import for src (via `importer`) and the dynamic import for <Player />
   * will be sequential and not parallel.
   *
   * We also cannot use an empty object as the default source
   * because <Player /> tries to access properties on the source object
   * and will throw an error if the source object is empty,
   * which breaks the animation during HMR.
   */
  const [srcToUse, setSrcToUse] = React.useState<object | string>(EMPTY_LOTTIE);

  React.useEffect(() => {
    if (src) {
      setSrcToUse(src);
    } else if (importer) {
      importer().then((module: { default: object | string }) => {
        setSrcToUse(module.default);
      });
    }
  }, [src, importer]);

  return srcToUse;
}
