import * as Sentry from '@sentry/react';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { isStagingOrLocal, readCookie, getApiUrl } from 'common/utils/urlUtil';
import AlertDialog from 'app/containers/dialogs/AlertDialog';
import qs from 'qs';

import { dataWithVersionSha, dataWithFullstorySession } from 'common/lib/versionIdentifyUtils';
import { transformAPIRequestDataForGodMode } from './godMode';
import { getExtensionContext } from 'chrome-extension/inject/core/lib/utils';
import extensionMessageTypes from 'chrome-extension/background/messageTypes';
import { serialize } from 'app/utils/ApiUtils';
import { isJSONParsingError } from 'app/utils/MiscUtils';
import { openDialog } from 'app/lib/openDialog';
import { getCurrentSite } from 'chrome-extension/inject/core/lib/locationUtils';

import {
  isPlainUnauthorizedErrorMsg,
  sendMessageToExtension,
} from 'chrome-extension/utils/ExtensionUtils';
import { recordApiCall } from 'common/lib/apiCallTracker';
import { RateLimitDialog } from 'app/containers/dialogs/RateLimitDialog';
import { SecurityChallengeDialog } from 'app/containers/dialogs/SecurityChallengeDialog';

export type FetchOptions = {
  data?: Record<string, unknown>;
  method?: 'get' | 'post' | 'put' | 'delete' | 'patch';
  credentials?: RequestCredentials;
  body?: unknown;
  csrfToken?: string;
  skipDecamalization?: boolean;
  skipCamalization?: boolean;
  abortSignal?: AbortSignal;
};

class _ApiClient {
  fetch: <T>(path: string, options?: FetchOptions) => Promise<T>;

  constructor() {
    this.fetch = async (path, options = {}) => {
      let { data } = options;
      if (!options.skipDecamalization) {
        data = decamelizeKeys(data);
      }

      data = dataWithVersionSha(data);
      data = transformAPIRequestDataForGodMode(data);
      data = dataWithFullstorySession(data);
      const method = options.method || 'get';
      if (!data) {
        data = {};
      }

      // https://app.asana.com/0/467924783835806/1148760341072779
      // force browser to make an api call instead of caching
      data.cacheKey = new Date().getTime();

      const requestOptions: Record<string, unknown> = {
        credentials: options.credentials || 'include',
      };

      requestOptions.method = method;

      if (options.body) {
        requestOptions.body = options.body;
      } else {
        requestOptions.headers = {
          'Content-Type': 'application/json',
        };
        if (method === 'get') {
          const dataQueryString = qs.stringify(data, {
            arrayFormat: 'brackets',
          });
          path += `?${dataQueryString}`;
        } else {
          requestOptions.body = JSON.stringify(data);
        }
      }

      if (!['get', 'head'].includes(method)) {
        const csrfToken = options.csrfToken || decodeURIComponent(readCookie('X-CSRF-TOKEN'));
        if (csrfToken && csrfToken !== 'null') {
          if (!requestOptions.headers) {
            requestOptions.headers = {};
          }
          requestOptions.headers['X-CSRF-TOKEN'] = csrfToken;
        }
      }

      if (options.abortSignal) {
        requestOptions.signal = options.abortSignal;
      }

      recordApiCall(path);

      let thePromise: Promise<unknown>;
      if (getExtensionContext() === 'content') {
        if (requestOptions.body && requestOptions.body instanceof FormData) {
          requestOptions.body = await serialize(requestOptions.body);
          requestOptions.serialized = true;
        }
        thePromise = sendMessageToExtension({
          type: extensionMessageTypes.FETCH_IN_BACKGROUND_PAGE,
          payload: {
            url: getApiUrl(path),
            options: {
              ...requestOptions,
              headers: {
                ...(requestOptions.headers ?? {}),
                'client-origin': getCurrentSite(),
              },
            },
          },
          old: true,
        });
      } else {
        thePromise = fetch(getApiUrl(path), requestOptions).then((response) => {
          // 201901 Scott: if backend 500's, the response won't be JSON formatted, so return this instead of breaking frontend
          if (response.status >= 500) {
            return { json: { result: response.status }, response };
          }

          if (process.env.IS_DEVELOPMENT) {
            // Used only on local environment when using `npm run proxy:backend`
            const proxyHost = response.headers.get('x-proxy-host');
            if (proxyHost) {
              window.__PROXY_HOST__ = proxyHost;
            }
          }

          // If the response is HTML, return the HTML content
          const contentType = response.headers.get('content-type');
          if (contentType?.includes('text/html')) {
            return response
              .clone()
              .text()
              .then((html) => ({ html, response: response.clone() }))
              .catch((e) => {
                console.error('Failed to parse HTML response', e);
                return Promise.reject(Object.assign(e, { response: response.clone() }));
              });
          }

          return response
            .clone() // Performing response.clone() allows reading json or text responses more than once.
            .json()
            .then((json) => ({ json, response }))
            .catch((e) => {
              if (isJSONParsingError(e)) {
                response
                  .clone()
                  .text()
                  .then((responseText) => {
                    console.error('Received non-json response', responseText);
                    Sentry.withScope((scope) => {
                      scope.setExtra('responseText', responseText);
                      scope.setTag('path', path);
                      scope.setTag('status', response.status);
                      Sentry.captureException(e);
                    });
                  });
              }

              return Promise.reject(Object.assign(e, { response: response.clone() }));
            });
        });
      }

      return thePromise.then(({ json, html, response }) => {
        /**
         * If API returns cf-mitigated, inside header and that's set to challenge
         * It means that the request has been flagged, show security challenge in a dialog
         */
        if (
          !process.env.IS_EXTENSION &&
          window.location.href.includes('turnstile') &&
          response.headers?.has('cf-mitigated') &&
          response.headers.get('cf-mitigated') === 'challenge'
        ) {
          return new Promise((resolve, reject) => {
            openDialog(SecurityChallengeDialog, {
              onSuccessCallback: () => {
                this.fetch(path, options)
                  .then(resolve) // Resolves after challenge is solved and API is called again
                  .catch(reject); // Reject if there is an error after challenge
              },
            });
          });
        }

        /**
         * If API returns 429, without cf-mitigated it means that the request has been rate limited.
         * And response is HTML, show it in a dialog to notify user about the temporary access
         */
        if (response.status === 429 && html?.length) {
          openDialog(RateLimitDialog, { html });
          return { error: 'API being rate limited', result: response.status };
        }

        if (response.status === 422 || response.status === 400) {
          const errorMessage = json.error;

          /*
          / https://apolloio.slack.com/archives/C03Q1KX15NC/p1708924516400939
          / Don't show alert dialog for "Unauthorized" error in extension.
          / Extension Team needs to do a permissions audit first and improve certain workflows
          / before we can re-enable this again.
          */
          const isExtensionUnauthorized =
            process.env.IS_EXTENSION && isPlainUnauthorizedErrorMsg(errorMessage);

          if (!json.skip_alert_dialog && !isExtensionUnauthorized) {
            openDialog(AlertDialog, {
              message: errorMessage,
              showAlertIcon: true,
              title: 'Error',
              renderMessageAsHtml: true,
            });
          }
          return { error: json.error, result: response.status, json };
        } else if (response.status >= 500) {
          if (isStagingOrLocal()) {
            response.text().then((text: string) => {
              const message = `Got HTTP 5XX error on ${method.toUpperCase()} - ${path} \n\n` + text;
              openDialog(AlertDialog, {
                message,
                showAlertIcon: true,
                title: 'Error',
                renderMessageAsHtml: true,
              });
            });
          }
          return { result: response.status, json };
        }

        if (options.skipCamalization) {
          return json;
        } else {
          return camelizeKeys(json);
        }
      });
    };
  }
}

const ApiClient = _ApiClient;
const apiClient = new ApiClient();
export default apiClient;
