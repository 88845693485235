// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_ufXWs{display:flex;align-items:stretch;gap:16px;overflow-x:auto}.zp_Zbxdy{outline-color:#146ef6;outline-width:1px;outline-offset:-2px;height:100%}.zp_Ati5U{position:relative;display:flex;flex-direction:column;align-items:center;padding:4px;gap:2px;margin-bottom:6px;min-width:max-content;border-radius:var(--border-radius-8)}.zp_rUFyf{display:flex;align-items:center;gap:4px}.zp_rUFyf.zp_tbfxM{gap:8px}.zp_O8C25{font-size:16px}.zp_j5_kw{font-size:14px;font-weight:400;line-height:20px;color:var(--color-text-body);grid-area:label}.zp_OV5jz{font-size:12px;font-weight:400;line-height:16px;color:var(--color-text-light);grid-area:subtext}.zp_j5_kw,.zp_OV5jz{text-align:center}.zp_HBTGF{grid-area:counter}.zp_Ati5U[data-active-item] .zp_j5_kw{font-size:14px;font-weight:600;line-height:20px;color:var(--color-text-header)}.zp_Ati5U[data-active-item] .zp_O8C25{color:var(--color-text-header)}.zp_Ati5U[data-active-item]::after{position:absolute;bottom:-6px;align-self:center;justify-self:center;place-self:center;display:block;border-bottom:2px solid var(--color-neutral-80);content:\"\";width:100%}.zp_Ati5U.zp_sSYzO{outline:0;box-shadow:0 0 0 1px var(--color-primary-50) inset,0 0 0 2px var(--color-neutral-0) inset}.zp_Ati5U:focus-visible{outline:0;box-shadow:0 0 0 1px var(--color-primary-50) inset,0 0 0 2px var(--color-neutral-0) inset}.zp_Ati5U[aria-disabled=true]{opacity:.2}.zp_Ati5U:hover{background-color:var(--color-neutral-20)}.zp_Ati5U:active{background-color:var(--color-neutral-30)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "zp_ufXWs",
	"tabPanel": "zp_Zbxdy",
	"tab": "zp_Ati5U",
	"tabLine": "zp_rUFyf",
	"topLine": "zp_tbfxM",
	"icon": "zp_O8C25",
	"label": "zp_j5_kw",
	"subtext": "zp_OV5jz",
	"count": "zp_HBTGF",
	"focus-visible": "zp_sSYzO"
};
export default ___CSS_LOADER_EXPORT___;
